import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import pageNotFoudImg from "../images/v2/404-page-error.svg";
import useWidth from "../hooks/useWidth";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
	listItemIcon: {
		color: "#fff",
		fontSize: 34,
		backgroundColor: "#08a742",
		borderRadius: "50%",
		padding: "5px",
		marginRight: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			fontSize: 28,
			marginRight: theme.spacing(1.5),
		},
	},
	ListItem: {
		display: "flex",
		alignItems: "center !important",
	},
	listItemText: {
		"& *": {
			fontSize: 22,
			color: "#2e3f4f",
			textDecoration: "none",
			[theme.breakpoints.down("sm")]: {
				fontSize: 16,
			},
		},
	},
	img: {
		maxWidth: "100%",
		height: "auto",
	},
}));

const NotFoundPage = () => {
	const classes = useStyles();
	const width = useWidth();
	return (
		<Container>
			<Grid alignItems="center" container justify="center">
				<Grid item md={6} px={6} sm={12}>
					<HeaderTypography component="h1" my={4}>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						The page you are looking for doesn't exist.
					</HeaderTypography>
					<ParagraphTypography mb={2}>Here are some quick links to help you navigate :</ParagraphTypography>
					<List>
						<ListItem alignItems="flex-start">
							<ListItemIcon>
								<CheckIcon className={classes.listItemIcon} />
							</ListItemIcon>
							<ListItemText className={classes.listItemText}>
								<Link to="/sales-pipeline-management-tool">Features</Link>
							</ListItemText>
						</ListItem>
						<ListItem alignItems="flex-start">
							<ListItemIcon>
								<CheckIcon className={classes.listItemIcon} />
							</ListItemIcon>
							<ListItemText className={classes.listItemText}>
								<Link to="/crm-platfrom-pricing">Pricing</Link>
							</ListItemText>
						</ListItem>
						<ListItem alignItems="flex-start">
							<ListItemIcon>
								<CheckIcon className={classes.listItemIcon} />
							</ListItemIcon>
							<ListItemText className={classes.listItemText}>
								<Link to="/contact">Contact Us</Link>
							</ListItemText>
						</ListItem>
					</List>
					{/* eslint-disable-next-line react/no-unescaped-entities */}
					<ParagraphTypography mb={4}>Still couldn't find what you were looking for?</ParagraphTypography>
					<Box textAlign={{ sm: "center", xs: "center" }[width] || "left"}>
						<Button
							className={classes.bookNowButton}
							color="secondary"
							component={Link}
							href="/"
							size="large"
							variant="contained"
						>
							Back to Home
						</Button>
					</Box>
					<br />
					<br />
					<br />
				</Grid>
				<Grid item md={6} sm={12}>
					<Box textAlign="center">
						<img alt="Pepper Cloud CRM" className={classes.img} src={pageNotFoudImg} />
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default NotFoundPage;
